/**
 * Default Variables.
 *
 * Modify this file to provide default Bootstrap Framework variables. The
 * Bootstrap Framework will not override any variables defined here because it
 * uses the `!default` flag which will only set their default if not already
 * defined here.
 *
 * You can copy existing variables directly from the following file:
 * ./THEMENAME/bootstrap/assets/stylesheets/bootstrap/_variables.scss
 */

// Set the proper directory for the Bootstrap Glyphicon font.
$icon-font-path: '../bootstrap/assets/fonts/bootstrap/';


// Variables

// Gray and Brand Colors for use across theme

$theme-primary:    #009ddc;
$theme-danger:     #e74c3c;
$theme-action:     #e74c3c;

$wrs-blue:         #009ddc;
$wrs-green:        #7ac143;
$wrs-yellow:       #eeb111;

$gray-base:     #000 !default;
$gray-darker:   lighten($gray-base, 13.5%) !default; // #222
$gray-dark:     lighten($gray-base, 20%) !default;   // #333
$gray:          lighten($gray-base, 33.5%) !default; // #555
$gray-light:    lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:   lighten($gray-base, 96%) !default; // 

$gray-wrs: 		 rgba(0,0,0,.7);

