
@import 'default-variables';
@import 'mixins';
@import '../bootstrap/assets/stylesheets/bootstrap';
@import 'overrides';

// Global Components

body {
    overflow-x: hidden;
    @include body-font;
}

.text-muted {
    color: $gray-light;
}

.text-primary {
    color: $theme-primary;
}

p {
    font-size: 18px;
    line-height: 1.75;
    color: $gray-wrs;
}

p.large {
    font-size: 19px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: none;
}

a {
    color: $theme-primary;
}

a:hover,
a:focus,
a:active,
a.active {
    color: darken($theme-primary, 10%);
}

h1, h2, h3, h4, h5, h6 {
    @include heading-font;
    text-transform: none !important;
   // font-weight: 700;
}

.front .col-sm-12 {
   background-color: #f2f2f5;
}


.front .main-container{
   width: 100%;
   max-width: 100%;
   padding: 0;
}

.front .well{
   margin-bottom: 0;
   border: 0px;
   background-color: #f2f2f5;
}



.well-lg{

  padding: 60px;
}


.img-centered {
    margin: 0 auto;
}

img {
    max-width: 100%;
    height: auto;
}

.not-logged-in .img-thumbnail{
    background-color: transparent;
    border: 0;
}

.bg-light-gray {
    background-color: $gray-lighter;
}

.bg-darkest-gray {
    background-color: $gray-darker;
}

// Restyled Primary Buttons


.btn{

    cursor: pointer;
    display: inline-block;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    text-transform: uppercase;


    letter-spacing: 2px;


    font-style: normal;
    padding: 14px 30px;
    border-radius: 0px ;


}


.btn-primary {
    @include button-variant(white, $theme-primary, $theme-primary);
    @include heading-font;
    font-weight: 700;
    color: #fff;
}

.btn-xl {
    @include button-variant(white, $theme-primary, $theme-primary);
    @include heading-font;
    font-weight: 700;
    border-radius: 0;
    font-size: 18px;
    padding: 20px 40px;
}

.btn-outline {
    border: 2px solid white;
    background-color: transparent;
    color: white;

    &:hover{
         background-color: white;
         color: $theme-primary;
    }
}

.btn-blue, .btn-black, .btn-green{
    color: white ;

    &:hover{
        opacity:0.8;
        color: white ;
    }

}

.btn-blue{
    background-color: $theme-primary;
    border-color: $theme-primary;
}

.btn-black{
    background-color: #000;
    border-color: #000;
}

.btn-green{
    background-color: #88BB44;
    border-color: #88BB44;
}

.btn-blue-outline{


    background-color: transparent;
    border-color: #009ddc !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-radius: 0;
    color: #009ddc !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 1em;
    margin-top: 20px;
    padding: 1em 2.5em;
    text-align: center;

    &:hover{
        background-color: #009ddc;
        color: white !important;
    }


}

.btn-white{
    background-color: #fff;
    color: $theme-primary;
     &:hover{
        opacity: 0.8;
     }
}






//annoucement bar

.alert-info, .alert-danger, .alert-sucess, .alert-warning{

    background-color: $theme-primary !important;
    border-color: $theme-primary;
    color:white;


    p,a, a:hover {
        color:white;
        font-size: 12px;
        text-align: center;
    }
}


.alert {
   margin-top: 50px;
  margin-bottom: 0;
}


@media(min-width:768px) {

.alert {

    padding:2px !important;

    margin:0;
    text-align: center;
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 10000;


}

.logged-in .alert{

  position: relative;
}

}





// Custom Navigation Bar

.navbar-custom {
    background-color: $gray-darker;
    border-color: transparent;
    .navbar-brand {
        color: $theme-primary;
        @include script-font;
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: darken($theme-primary, 10%);
        }
    }
    .navbar-collapse {
        border-color: fade-out(white, .02);
    }
    .navbar-toggle {
        background-color: $theme-primary;
        border-color: $theme-primary;
        @include heading-font;
        color: white;
        font-size: 12px;
        &:hover,
        &:focus {
            background-color: $theme-primary;
        }
    }
    .nav {
        li {
            a {
                @include heading-font;
                font-size: 16px;
                font-weight: 400;
                text-transform: capitalize;
                letter-spacing: 1px;
                color: white;
                &:hover,
                &:focus {
                    color: $theme-primary;
                    background-color: transparent;
                    outline: none;
                }
            }
        }
    }

    .navbar-nav>.active>a {
        border-radius: 0;
        color: white;
        background-color: $theme-primary;
    }
    .navbar-nav>.active>a:hover,
    .navbar-nav>.active>a:focus {
        color: white;
        background-color: darken($theme-primary, 10%);
    }

    &.affix {
        .navbar-nav>.give>a {
            border-radius: 3px;
            color: white;
            //padding-left: 27px;
            //padding-right: 27px;
            background-color: $theme-primary;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
            max-width: 85px;
            margin-left:10px;
        }
        .navbar-nav>.give>a:hover,
        .navbar-nav>.give>a:focus {
            color: white;
            background-color: darken($theme-primary, 10%);
        }
        .logo img{
            width: 164px;
        }
    }
}

.navbar-custom .logo img{
    width: 100px;
    margin-left: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}


 /*-- change navbar dropdown link color --*/

.navbar-custom .dropdown-menu > li > a{
   color:#000;
  }

  .navbar-nav > li > .dropdown-menu{
    margin-left: 11px;
  }


 .navbar-toggle:before {

    left:-5px;
    top:8px;
    position:absolute;
    width:51px;
  }

  .navbar-toggle .icon-bar{
    background-color: white;
  }



@media(min-width:768px) {

   .navbar-nav {
        float:right;
    }

    .navbar-custom .logo img{
        margin-left: 0;
    }


    .navbar-custom {
        background-color: transparent;
        padding: 40px 0;
        -webkit-transition: padding 0.3s;
        -moz-transition: padding 0.3s;
        transition: padding 0.3s;
        border: none;
        .logo img{
            width: 200px;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }
        .navbar-nav>.active>a {
            border-radius: 3px;
        }

    }

}

// Navbar Change on Scroll

@media(min-width:768px) {
    .navbar-custom.affix {
        background-color: $gray-darker;
        padding: 10px 0;
        .navbar-brand img{
            width: 100px;
        }
        .donate {
         //   .btn-primary;
        }
    }
}


@media(max-width:768px){

    .navbar-custom .dropdown-menu > li > a {
        color:white !important;
    }

     .well-lg{
      padding: 60px 40px 10px 40px!important;
    }

}

.not-front header {
//padding-top:20px;
//background-color: $gray-darker;
}

.image-overlay, .featured-image {
    background-color: rgba(51, 51, 51, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.image-overlay,
#page-header section {
    margin:0;
    padding:0;
    }
    .featured-image {
    overflow: hidden;
    /*position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.featured-image img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
    overflow-x: hidden;
}

body.navbar-is-fixed-top {
    padding-top: 0 !important;
}

body.admin-menu.navbar-is-fixed-top {
    padding-top: 16px !important;
}

#page-header section {
    margin:0;
    padding:0;
}



.front-page-featured, .page-featured {
    position: relative;
    overflow: hidden;
    // padding-bottom: 100px;
     z-index: 1;
     pointer-events: none;


    /* background-image: url('../img/backgrounds/bk-nu.jpg'); */
/*    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    @include background-cover;*/
    text-align: center;

    .img-responsive{
        width: 100%;
    }

    .intro-text {
        position: relative;
        padding-top: 70px;
        padding-bottom: 0px !important;
        pointer-events: none;
         margin: 0 auto;

         text-align: center;
         pointer-events: auto;



        .intro-lead-in {
            @include serif-font;
            font-style: italic;
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 25px;

        }
        .intro-heading {
            @include heading-font;
            font-weight: 700;
            font-size: 50px;
            line-height: 50px;
            margin-bottom: 25px;

            text-transform: none;
        }




    }



    .no-content {
        padding-bottom: 0;
    }

    .no-link{
      padding-bottom: 0;
    }





}


.image-overlay{

   position: relative;
}

.intro-page{
    padding-top: 50px;
    pointer-events: auto;
}

.front-page-featured .intro-text{
    text-shadow: 1px 1px 1px black;
}


.view-front-page-featured .container {
    max-width: 700px;
    margin: 0 auto;
}



@media(min-width:1300px) {


   .front-page-featured, .page-featured{

      #thumbnail {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: auto;
      }

    .intro-text {
        padding-top: 300px;
        color: white;
        max-width: 850px;
    }

    .intro-text h1{
        text-transform: none;
    }

    .intro-text p {
        color:white;
    }


   .no-content,  .no-content{
      padding-bottom: 600px;
   }


   .no-link {
      padding-bottom: 500px;
    }

 }

   .page-featured{
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    img {
    height: auto;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    }

    p {
        color:white;
    }

    .intro-page{
    color:white;


    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99
    }

    .image-overlay{
    background-color: rgba(51, 51, 51, 0.3);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99
    }

    .description {
        padding: 200px 0 0 0;
        pointer-events: auto;
        max-width: 900px;
        margin: 0 auto;

        h1, h2, h3{
            line-height: 1.2 !important;
        }

    }



}

.view-id-front_page_featured .view-footer h2{
   font-weight:  400px !important;
   text-transform: none;
}



}


.node-type-donation-pages{

    .main-container{
        max-width: 920px;
    }

    .alert{
        display: none !important;
    }

    .navbar-nav{
      display: none;
    }




}

@media(min-width:1600px) {

  .no-content,  .no-content{
     padding-bottom: 730px !important;
  }

.view-front-page-featured  .intro-text {
      padding-top: 350px !important;

  }

}

.page-node-736{

    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .field-name-body div{
       margin:0;
    }
}




.page-node-736 {
  background-image: url(https://worldrelieftriad.org/sites/default/files/_DSC3587.jpg);


}





section#donate {
    padding: 125px 0;
}


@media(min-width:1700px) {

  .no-content,  .no-content{
     padding-bottom: 750px !important;
  }

}


// form styles

.node-type-donation-pages footer, .node-type-donation-pages .breadcrumb{
    display: none;
}

.node-type-donation-pages .node-donation-pages{

    padding: 30px;
    margin-top: 100px;
    background: #fff none repeat scroll 0 0;

    max-width: 730px;
    overflow: hidden;


}

#bbox-root form {

    font-family: Lato;
    max-width: 900px;
    padding: 0;
    margin: 0;
    max-width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    @extend .form-horizontal;
}


#bboxdonation_gift_lblHeadingDonation{
    font-size: 28px;
}

label, #bboxdonation_billing_lblFullName{
    color: rgba(0,0,0,.6);
    font-weight: normal;
 }

 #bboxevent_billing_lblHeadingContact, #bboxevent_payment_lblHeadingPayment,
 .BBFormSectionHeading, #bboxevent_registrant_templateRegistrant_lblIsPayer,
 #bboxevent_attributes_lblHeadingAttributes, .BBFormRegistrantHeading,
 .BBFormRegistrantAttribsHeading {
   font-weight: bold;
 }


.BBFormSectionHeading {

      border-color: currentcolor currentcolor #ccc;
      border-style: none none solid;
      border-width: 1px;
      margin-left: 20px !important;
      margin-right: 20px !important;

}

.BBDFormSectionTributeInfo legend {
      display: none;
}


#bboxdonation_recurrence_lblRecurringGift {
    color: #009ddc;
    font-size: 12pt;
    font-weight: bold;
}


#bboxdonation_recurrence_divHeadingRecurrence{
    display: none;
}

.BBFormRadioAmount, .BBFormRadioDescriptionOther{
   font-size: 17pt !important;
}

#BBFormSectionHeading, #bboxevent_BBEmbeddedForm label, #bboxevent_BBEmbeddedForm span {
   color: rgba(0, 0, 0, 0.6);
}

.BBFormRadioLabelGivingLevelNotSelected:hover {
     background:  #f0f0f0 none repeat scroll 0 0 !important ;

}


#bboxdonation_tribute_lblTributeGift,
#bboxdonation_recurrence_lblRecurringGift{
    font-weight: bold ;
}


.BBFormRadioList, #bboxdonation_recurrence_divRecurrenceCheckbox,
#bboxdonation_tribute_divTributeGeneral{
      margin-left: 20px;

}

#bboxdonation_billing_fldOrgGift{
     margin-left: 40px
}

#bboxdonation_tribute_divTributeGeneral{
        margin-top: -5px;
        font-weight: 600px;
}

.BBFormRadioItem {
      display: inline-block;
      clear: none;
}

.BBFormRadioLabelGivingLevelNotSelected, .BBFormRadioGivingLevelItem,
.BBFormRadioLabelGivingLevel {
      border-radius: 0px !important;
}

.BBFormRadioLabelGivingLevel{

    border-radius: 0px;
    border: 1px solid #666;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 1.8em;
    line-height: 1em;
    padding: 10px;
    text-align: center;
    transition: all 0.4s ease 0s;
    width: 100%;
  }

.BBFormRadioLabelGivingLevelSelected {
      background: #009ddc none repeat scroll 0 0;
      border: 1px solid #06c;
      color: #fff;
      font-size: 1.8em;
    }

     .BBFormRadioGivingLevelItem{

        width: 45%;
    }

    .BBFormButtonRow {
      padding-bottom: 20px;
    }


#bboxevent_btnSubmit{


  border-radius: 0;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1em;
  margin-top: 40px;
  padding: 1em 2.5em;
  text-align: center;
  text-transform: uppercase;


}

#bboxdonation_btnSubmit{
    background-color: transparent;
}

#bboxevent_btnSubmit{
  background-color: #009ddc !important;
}


#bboxevent_btnSubmit:hover, #bboxdonation_btnSubmit:hover {
    background-color: #009ddc !important;
    color: white !important;
}

.BBFormSubSectionRegistrantAttributes input, #txtAddedDonation{

  height: 60px;

}

 #bboxevent_billing_divBillingSection input,  #bboxevent_billing_divBillingSection select,
 .BBDFormSectionPaymentInfo input, .BBDFormSectionPaymentInfo select,
 .BBFormAddressBlock .BBFormIndivFields input, .BBFormAddressBlock .BBFormIndivFields select,
  #bboxevent_registrant_templateRegistrant_RegistrantAddress_txtCity_210_0_0,
  #bboxevent_registrant_templateRegistrant_RegistrantAddress_ddCountry_210_0_0{
     height: 40px;
 }

 .BBFormTableRowCel span{
   font-weight: normal;
 }


@media(min-width:1200px){

    .node-type-donation-pages .node-donation-pages{
        margin-left: -40px;
    }
}




@media(min-width:768px) {
    header {
        .intro-text {
            padding-top: 380px;
            padding-bottom: 120px;
            .intro-lead-in {
                @include serif-font;
                font-style: italic;
                font-size: 40px;
                line-height: 40px;
                margin-bottom: 25px;
            }
            .intro-heading {
                @include heading-font;
                font-weight: 700;
                font-size: 75px;
                line-height: 75px;
                margin-bottom: 50px;
            }
        }
    }
}



// Global Section Styles

section {
    padding: 100px 0;
    h2.section-heading {
        font-size: 40px;
        margin-top: 0;
        margin-bottom: 15px;
    }
    h3.section-subheading {
        font-size: 20px;
        font-size: 2.0rem;
        line-height: 39px;
        line-height: 2.9rem;
        //@include serif-font;
        text-transform: none;
        //font-style: italic;
        font-weight: 400;
        margin-bottom: 75px;
    }
}
#tranparency h3.section-subheading, #services h3.section-subheading {
    margin-bottom: 25px;
}

@media(min-width:768px) {
    .not-logged-in section {
        padding: 40px 0;
    }
}

// How we help section

#block-views-how-we-help-block{

   text-align: center;
    background-color: white;

   padding-bottom: 0;


   .view-header{
      padding-bottom: 20px;
   }

   .block-title{
      text-transform: none;
   }

   .col-md-4{
      padding: 0;
   }

   .has-title{
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(43, 47, 51, 0.3) none repeat scroll 0 0;
    pointer-events: none;
 }

 .slide-content{
    padding: 15%;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    width: 100%
 }

 .slide-content a {

   font-size: 25px;
   font-weight: bold;
   //text-transform: uppercase;
   color:white;

 }

 img {
   width: 100%;
   padding: 0;
   border: 0;
 }



}


// Featured ad image only

#block-views-ad-front-page-block {
       padding-bottom: 0;
}

#block-views-ad-front-page-block img {
   width: 100%;

}


@media(max-width:767px) {

    .front .view-header{
           padding: 0 60px 25px;
    }

    .front.not-logged-in #block-system-main {
        display: none;
    }

    .front .intro-heading{
        padding: 0 20px;
    }
}


// Get Involved Section
#block-views-get-involved-block {
    text-align: center;
    background-color: #f2f2f5;

    .block-title{
      text-transform: none;
    }

    img {
      margin: 0 auto;

    }

    h4 {
        font-weight: 700;
        text-transform: none;
        font-size: 18px;
     }

    .view-header {
       margin-bottom: 40px;
    }
    .get-involved-item {
        margin: 0 0 15px;
        right: 0;

        .get-involved-link {
            display: block;
            position: relative;
            max-width: 400px;
            margin: 0 auto;
            color: black !important;
            .get-involved-hover {
                background: fade-out($theme-primary, .9);
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all ease 0.5s;
                -webkit-transition: all ease 0.5s;
                -moz-transition: all ease 0.5s;
                &:hover {
                    opacity: 1;
                }
                .get-involved-hover-content {
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    font-size: 20px;
                    text-align: center;
                    top: 50%;
                    margin-top: -12px;
                    color: white;
                    i {
                        margin-top: -12px;
                    }
                    h3,
                    h4 {
                        margin: 0;
                    }
                }
            }
        }
        .get-involved-caption {
            max-width: 400px;
            margin: 0 auto;
            background-color: white;
            text-align: center;
            padding: 25px;
            h4 {
                text-transform: none;
                margin: 0;
            }
            p {
                @include serif-font;
                font-style: italic;
                font-size: 16px;
                margin: 0;
            }
        }
    }
    * {
        z-index: 2;
    }
}

@media(max-width:767px) {

  .view-get-involved {

    .img-responsive{
        max-width: 160px !important;
        display: inline-block !important;

    }

    .col-md-3 {
       padding: 20px;
    }

  }

  .frontnot-logged-in .block-system-main{
    display: none;
  }

  #block-views-get-involved-block  h2{
      padding: 20px;
  }

   #block-views-get-involved-block h4{
     margin-top: 20px;
   }
}

@media(min-width:767px) {
    #get-involved {
        .get-involved-item {
            margin: 0 0 30px;
        }
    }
}



@media(min-width:768px) {
    .timeline {
        &:before {
            left: 50%;
        }
        > li {
            margin-bottom: 100px;
            min-height: 100px;
            .timeline-panel {
                width: 41%;
                float: left;
                padding: 0 20px 20px 30px;
                text-align: right;
            }
            .timeline-image {
                width: 100px;
                height: 100px;
                left: 50%;
                margin-left: -50px;
                h4 {
                    font-size: 13px;
                    margin-top: 16px;
                    line-height: 18px;
                }
            }
            &.timeline-inverted > .timeline-panel {
                float: right;
                text-align: left;
                padding: 0 30px 20px 20px;
            }
        }
    }
}

@media(min-width:992px) {
    .timeline {
        > li {
            min-height: 150px;
            .timeline-panel {
                padding: 0 20px 20px;
            }
            .timeline-image {
                width: 150px;
                height: 150px;
                margin-left: -75px;
                h4 {
                    font-size: 18px;
                    margin-top: 30px;
                    line-height: 26px;
                }
            }
            &.timeline-inverted > .timeline-panel {
                padding: 0 20px 20px;
            }
        }
    }
}

@media(min-width:1200px) {
    .timeline {
        > li {
            min-height: 170px;
            .timeline-panel {
                padding: 0 20px 20px 100px;
            }
            .timeline-image {
                width: 170px;
                height: 170px;
                margin-left: -85px;
                h4 {
                    margin-top: 40px;
                }
            }
            &.timeline-inverted > .timeline-panel {
                padding: 0 100px 20px 20px;
            }
        }
    }
}

// Footer

.footer{
      margin-top: 0;
}

footer {
    background-color: $gray-darker;
    background-position: center;
    background-repeat: no-repeat;

    .info {
        text-align: center;
        font-size: 10px;
        color:$gray-light;

    }


    p {
         color: #777777;
         font-size: 14px;

    }



    .section-heading {
        color: white;
         font-size:14px !important;
         text-transform: none !important;

        font-weight: 700;
    }
    .form-group {
        margin-bottom: 25px;
        input,
        textarea {
            padding: 20px;
        }
        input.form-control {
            height: auto;
        }
        textarea.form-control {
            height: 236px;
        }
    }
    .form-control:focus {
        border-color: $theme-primary;
        box-shadow: none;
    }
    ::-webkit-input-placeholder {
       @include heading-font;
       font-weight: 700;
       color: $gray-lighter;
    }
    :-moz-placeholder { /* Firefox 18- */
       @include heading-font;
       font-weight: 700;
       color: $gray-lighter;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
       @include heading-font;
       font-weight: 700;
       color: $gray-lighter;
    }
    :-ms-input-placeholder {
       @include heading-font;
       font-weight: 700;
       color: $gray-lighter;
    }
    .text-danger {
        color: $theme-danger;
    }
}

footer {

   section {
      padding: 30px 0;
   }


    text-align: center;
    span.copyright {
        line-height: 30px;
        @include heading-font;
        text-transform: none;

        color: #777777;

    }
    ul.quicklinks {
        margin-bottom: 0;
        line-height: 40px;
        @include heading-font;
        text-transform: none;
    }
}

// Social Buttons

ul.social-buttons {
    margin-bottom: 0;
    li {
        a {
            //display: block;
            background-color: white;
            height: 40px;
            width: 40px;
            border-radius: 100%;
            font-size: 20px;
            line-height: 40px;
            color: black;
            outline: none;
            margin-top: 20px;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
            &:hover,
            &:focus,
            &:active {
                background-color: $theme-primary;
            }
        }
    }
}

.fa > span {
   display: none;
}

#global-site {


    margin-top: 15px;
   a {
   background-color: transparent;
   font-size:14px;
   text-decoration: none;
   margin-left:35px;

   }
}



@media(min-width:1200px) {

   .front.not-logged-in #block-system-main{
      display:none;
   }

   #block-block-17 {
    padding-bottom: 25px;
    padding-top: 80px;
    }

   footer {
      section {
         padding: 60px 0 50px 0;
      }

     #global-site, span.copyright, .section-heading, p {
      text-align: left;


     }

     span.copyright {
      margin-left: 35px;
     }

     ul.social-buttons a{
      margin-top:0;
     }

     #block-menu-menu-social-media-footer{
        overflow: hidden;
    }


   }
}

@media(max-width:768px){

    #block-block-17 {
        padding: 80px 23px 3px 30px;
    }

}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: none;
}

// Highlight Color Customization

::-moz-selection {
    text-shadow: none;
    background: $theme-primary;
}

::selection {
    text-shadow: none;
    background: $theme-primary;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

body {
    webkit-tap-highlight-color: $theme-primary;
}

.rounded img {
    padding:10px 20px;
    max-width: 300px;
    margin: 0px auto;
    background: lighten($gray-light, 15%);
    @extend .img-circle;
}
.involve-furnish{background: lighten($gray-light, 15%);}
.involve-event{background: lighten($wrs-yellow, 15%);}
.involve-volunteer{background: lighten($wrs-green, 15%);}
.involve-mobilize{background: desaturate(lighten($wrs-blue, 20%), 20%);}

.involve-furnish:hover{background: $gray-light;}
.involve-event:hover{background: $wrs-yellow;}
.involve-volunteer:hover{background: $wrs-green;}
.involve-mobilize:hover{background: $wrs-blue;}

#block-views-get-involved-block {
    .views-row-1 {
        img { @extend .involve-volunteer; }
    }
    .views-row-2 {
        img { @extend .involve-furnish; }
    }
    .views-row-3 {
        img { @extend .involve-event; }
    }
    .views-row-4 {
        img { @extend .involve-mobilize; }
    }
}

a,
a:hover,
a:focus,
a:active,
a.active {
    cursor: pointer !important;
}


.event strong {
  font-size: 3em;
  border-left: .7em solid #ccc;
  margin: 1em .5em;
  padding: .1em 1em;
  line-height: 1.2em;
}

#halo {
padding: 20px 0;

img {
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
    }
}


.logged-in #navbar {

    margin-top: 35px ;

}

.front #block-system-main{
     padding:    0 !important;
}



/** love in action **/

.page-node-789 h2{

    text-transform: none !important;
    color: rgb(92, 90, 89);
    font-size: 24px;
}


.page-node-789 .container, .page-node-789 .main-container{

    width: 100% !important;
    max-width: 100% !important;

}

.page-node-789 #mongo-form, .page-node-789 #bbox-root form{
    margin: 0 auto ;
    max-width: 700px ;
}


.page-node-789 #bboxdonation_gift_lblHeadingDonation{
    font-size: 14px;

}


.page-node-789 #navbar{
    display: none;

}

.page-node-789 .node {

    margin: 0 auto;
    max-width: 1200px;
}

.page-node-789 section{
    padding: 40px 0;
}

.page-node-789 h1, .page-node-789 .block-title {
    font-size: 23px;
    color:#5c5a59;
}

.page-node-789  .block-title {
    text-align: center !important;
    text-transform: none;
}

.page-node-789 .node p, #block-block-20 p {
    max-width: 700px;
    margin: 0 auto;
    margin-top:20px;
    font-size: 16px;
}

.page-node-789 #mongo-form{

    margin: 0 auto;

}

#block-block-20{
  background-color: #f5f3f2;
}


.page-node-789  .block-title{
    text-transform: none;
    margin-bottom: 30px;
   }




/** Newsletter*/

.page-node-821 {

     background-image: url("https://worldreliefdurham.org/sites/default/files/give-bk.jpeg");



     .breadcrumb{
        display: none;
     }

    .region-content{
        padding-top: 120px;
    }

    #content{
        margin-top: 5px;
        opacity: 0.9;
    }

    p{
        margin:0;
    }



    article{
        background:none !important;
    }

    #page{
        margin: 10px 0px 0px 0px !important;
    }

    .wFormContainer{
        max-width: 470px;
        border-style: none;
        border-width: 0px;
        border-color: transparent;
        opacity: 0.9;


    }

    .wFormTitle {


        background-image: none;
        text-align: left;
        margin-bottom:0;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.6);
        font-family: 'Lato', sans-serif;
        text-transform: uppercase;

    }

    label, .htmlContent {
        font-family: 'Lato', sans-serif;
        color: rgba(0, 0, 0, 0.6);


    }

    .label {

        font-size: 10px;
    }

    #tfa_1875-L{
        text-align: left;
        font-size: 12px;
    }

    .wForm input[type="text"], .wForm input[type="password"], .wForm input[type="file"], .wForm textarea, .wForm select{
        border-radius: 0;

    }



    #tfa_0-A{
       margin-top: 0;
       padding:0;
    }

    #submit_button{

        font-family: 'Lato', sans-serif;
        text-transform: uppercase;
        background-color: transparent;
        background-image: none;
        border-color: #009ddc !important;
        border-style: solid !important;
        border-width: 2px !important;
        border-radius: 0;
        color: #009ddc !important;
        font-family: "gotham";
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1.5px;
        line-height: 1em;
        margin-right: 120px;
        margin-top: 20px;
        padding: 1em 1.5em;
        text-align: center;
        text-transform: uppercase;
        margin-left:10px;

        &:hover{

            background-color: #009ddc;
            color:white !important;

        }


    }

    .supportInfo {
        padding: 0 10px !important;
        text-align: right;

    }

    .supportInfo a{
        font-size: 14px;
    }

    .wForm .preField, .wForm .inlineLabel{
        font-size: 15px;
    }

    @media all and (min-width: 750px) {


        .wForm input[type="text"], .wForm input[type="password"], .wForm input[type="file"], .wForm textarea, .wForm select{

            height: 30px !important;
        }

        #tfa_1873{
            width: 410px !important;
        }


        .wFormTitle {

        font-size: 24px;
        height: 60px !important;

        }





    }
}

.wFormContainer {

    label, span {
        color:black;
        font-weight: normal;
        text-align: left;
    }

}

.bg-gray {

  background-color: #f5f5f5;
  padding:20px;
}


#block-imageblock-1  img {
  max-width: 100%;
  width: 100%;

  .img-responsive{
    width: 100%;
  }
}

.view-get-involved .linkicon--no-text a, .view-get-involved .linkicon a{

    border-radius: 80%;
    color: white;
    font-size: 5.2em;
    margin: 0.15em;
    padding: 0.85em 0.85em;

  }

.blue{
      background: #009ddc;
}

.yellow{
  background: #f3c760;
}

.gray{
   background:  #9d9d9d;
}

.green {
  background: #a4d383;
}


/*** blog ***/



#block-views-blog-block-1{
    padding-top: 0;

}

.title-blog{

   text-align: center;
   color:#5c5c5c;

}

.view-id-blog .views-field-created{
    text-align: center;

}



.view-id-blog .views-field-body p, .view-id-blog .views-field-body ul,
.view-id-blog .views-field-body span, .view-id-blog .hrwr, .field-name-body div   {

    max-width: 1080px;
    margin: 0 auto;
    line-height: 1.6em;
    font-size: 18px;


}

.view-id-blog .views-field-body p, .view-id-blog .views-field-body ul,
.view-id-blog .views-field-body span, .view-id-blog .hrwr {
    margin-top: 17px;
}


.view-id-blog img {
  margin-bottom: 10px;
}

 .view-id-blog div{

      font-size: 18px;
      margin: 0 auto;
      color: rgba(0, 0, 0, 0.6);

}

.views-field-field-full-name{
    text-align: center;
}

.view-id-blog .views-field-body div .img {
  padding:10px;
}

.view-id-blog .views-row{
    padding-top: 120px;
}

.view-id-blog  .views-row-first{
    padding-top: 20px;
}

.view-id-blog  .field-name-field-article-images{
    padding-top: 40px;
}

.title-blog a {
  color: #5c5c5c !important;

  &:hover{
    text-decoration: none;
  }
}




.view-get-involved .linkicon--no-text a, .view-get-involved .linkicon a{

    border-radius: 80%;
    color: white;
    font-size: 5.2em;
    margin: 0.15em;
    padding: 0.85em 0.85em;

  }

.blue{
      background: #009ddc;
}

.yellow{
  background: #f3c760;
}

.gray{
   background:  #9d9d9d;
}

.green {
  background: #a4d383;
}



.region-sidebar-second{
    background-color: #f5f5f5;
}




@media only screen and (max-width: 750px) {
  .region-sidebar-second{
      background-color: transparent;
  }

  #book-block-menu-597{
    display: none;
  }

  .main-container .col-sm-9{
    padding-bottom: 0;
    padding-top: 0;
  }

  .not-logged-in #block-system-main{
    padding:0;
  }



}





@media only screen and (min-width: 970px) {

  .node-type-toolkit .col-sm-9 {
    width: 59% !important;
  }

  .node-type-toolkit .col-sm-3 {
    width: 40% !important;
  }

}


@media only screen and (max-width: 970px) {

  .node-type-toolkit .col-sm-9 {
    width: 100% !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .node-type-toolkit .col-sm-3 {
    width: 100% !important;
    margin-top:0;
  }

}





.node-type-toolkit .region-sidebar-second {
  background-color: #f5f5f5;
  padding: 8px;
}



.text-center {
  margin-top: 15px;
  text-align: center;
}

.book-toc {
  margin-left: -35px;
}

.book-toc li {
  margin: 10px;
}

.book-toc .expanded a:hover {
  text-decoration: underline;
}

.book-toc .menu,
.book-toc ul {
  padding-inline-start: 35px;
}




@media only screen and (max-width: 750px) {
  .region-sidebar-second {
    background-color: transparent;
  }

  .main-container .col-sm-9 {
    padding-bottom: 0;
    padding-top: 0;
  }

  .not-logged-in #block-system-main {
    padding: 0;
  }
}


.book-toc .active .dropdown-menu,
.book-toc .active.expanded .dropdown-menu
.book-toc .active.expanded ul:parent {
  display: block;
}

.book-toc .active >ul{

  display: block;
}

 .region-sidebar-second .book-toc:after  {
    display: none;
}

.region-sidebar-second .form-search .btn {
  margin-top: 0;
  padding: 6px;
}

#block-book-navigation{
  display: none;
}
