// Mixins

// Bootstrap Button Variant

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Background Cover Mixin

@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

// Font Mixins

@mixin serif-font {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin script-font {
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}

@mixin body-font {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin heading-font {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
